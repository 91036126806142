<template>
  <div :class="['incident-types', { loader: loader }]">
    <ui-loader v-model="loader" />
    <UiNotification v-model="showNotification" :message="$t('titles-successfully-updated')" />
    <Table
        v-if="!loader"
        :title="$t('incident-types')"
        :actionText="$t('add-subtype')"
        :showAction = false
        trash-is-hidden
        :data="getTypes"
        :descriptionList="descriptionList"
        hideDeleteIcon
        hideEditIcon
        @changeRowField="changeRowField($event)"
        :sort="true"
        :isGlobeIcon="true"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UiLoader from "@/components/ui/UiLoader";
import UiNotification from "@/components/ui/UiNotification";

export default {
  name: "IncidentTypes",
  components: {
    UiNotification,
    UiLoader,
    Table: () => import("@/components/ui/UiTable"),
  },

  data() {
    return {
      descriptionList: [
        {title: this.$t('title'), width: 440}
      ],
      loader: false,
      showNotification: false
    }
  },
  methods: {
    ...mapActions(['types', 'editType']),
    changeRowField(data) {
      this.loader = true;
      this.editType({
        id: data.id,
        name: data.name,
        ord: +data.ord,
        nameIntEn: data.nameIntEn
      })
      .then(() => {
        this.types()
        setTimeout(() => {
          this.loader = false
          this.showNotification = true
        }, 500)
      })
    },
  },
  computed: {
    ...mapGetters(['getTypes'])
  }
}
</script>

<style lang="scss" scoped>
 .incident-types {
   width: 100%;
   overflow-x: auto;

   &.loader {
     display: flex;
     justify-content: center;
     align-items: center;
   }
 }
</style>